import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"
import Img from "gatsby-image"

import "../scss/components/relatedPosts.scss"

const RelatedPosts = ({ posts }) => (
  <div className="relatedPosts">
    <h3 className="relatedSectionTitle">
      <span className="relatedSectionTitle_l">
        Related<span> </span>Posts
      </span>
      <span className="relatedSectionTitle_s">関連記事</span>
    </h3>

    <ul className="relatedPostsList">
      {posts.map(({ node }) => (
        <li key={node.id} className="relatedPostsList_item">
          <AniLink
            fade
            duration={1}
            to={`/${node.slug}`}
            className="relatedPostsList_img"
          >
            {node.featured_media && (
              <Img
                fluid={node.featured_media.localFile.childImageSharp.fluid}
                alt={node.title}
              />
            )}
          </AniLink>
          <div className="relatedPostsInfo">
            <span className="relatedPostsInfoMeta">
              <span className="relatedPostsInfoMeta_date">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                  <g>
                    <g>
                      <rect
                        className="cls-1"
                        width="48"
                        height="48"
                        fill="none"
                      />
                    </g>
                    <g className="relatedPostsInfoMeta_date-icon">
                      <path
                        className="cls-2"
                        d="M16,10H8a2,2,0,0,0-2,2V40a2,2,0,0,0,2,2H40a2,2,0,0,0,2-2V12a2,2,0,0,0-2-2H38"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="4px"
                      />
                      <line
                        className="cls-2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="4px"
                        x1="22"
                        y1="10"
                        x2="32"
                        y2="10"
                      />
                      <line
                        className="cls-2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="4px"
                        x1="16"
                        y1="6"
                        x2="16"
                        y2="14"
                      />
                      <line
                        className="cls-2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="4px"
                        x1="32"
                        y1="6"
                        x2="32"
                        y2="14"
                      />
                      <line
                        className="cls-2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="4px"
                        x1="6"
                        y1="21"
                        x2="34"
                        y2="21"
                      />
                    </g>
                  </g>
                </svg>
                <span className="relatedPostsInfoMeta_date-txt">
                  {node.date}
                </span>
              </span>
              {node.date < node.modified && (
                <span className="relatedPostsInfoMeta_modified">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                    <g>
                      <g>
                        <rect
                          className="cls-1"
                          width="48"
                          height="48"
                          fill="none"
                        />
                      </g>
                      <g className="relatedPostsInfoMeta_modified-icon">
                        <polyline
                          className="cls-2"
                          points="17.18 15 8.18 15 8.18 6"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="4px"
                        />
                        <path
                          className="cls-2"
                          d="M10.58,12A18,18,0,1,1,6.23,26.88"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="4px"
                        />
                      </g>
                    </g>
                  </svg>
                  <span className="relatedPostsInfoMeta_modified-txt">
                    {node.modified}
                  </span>
                </span>
              )}
              <AniLink
                fade
                duration={1}
                to={`/categories/${node.categories[0].slug}`}
                className="relatedPostsInfoMeta_cat"
              >
                <span>{node.categories[0].name}</span>
              </AniLink>
            </span>
            <AniLink fade duration={1} to={`/${node.slug}`}>
              <h3
                className="relatedPostsInfo_title"
                dangerouslySetInnerHTML={{ __html: node.title }}
              />
            </AniLink>
          </div>
        </li>
      ))}
    </ul>
  </div>
)

export default RelatedPosts
