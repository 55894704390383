import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import {
  FacebookShareButton,
  TwitterShareButton,
  LineShareButton,
  FacebookIcon,
  TwitterIcon,
  LineIcon,
} from "react-share"
import Layout from "../components/layout"
import SEO from "../components/seo"
import RelatedPosts from "../components/relatedPosts"
import RecentPosts from "../components/recentPosts"
import Comment from "../components/comment"
import MiniBio from "../components/miniBio"
import Banner from "../components/banner"
import "prismjs/themes/prism-okaidia.css"
import Prism from "prismjs"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import "../scss/templates/blogPost.scss"

class BlogPostTemplate extends React.Component {
  componentDidMount() {
    Prism.highlightAll()
  }

  render() {
    const { relatedPosts } = this.props.pageContext
    const post = this.props.data.wordpressPost
    const location = this.props.location
    return (
      <Layout location={location} crumbLabel={post.title}>
        <SEO
          title={post.title}
          description={post.excerpt}
          thumbnail={post.featured_media.localFile.childImageSharp.fluid.src}
        />
        <div className="blogPostWrap">
          <div className="blogPost">
            <div className="blogPostHead">
              {post.featured_media && (
                <Img
                  className="blogPostHead_img"
                  fluid={post.featured_media.localFile.childImageSharp.fluid}
                />
              )}
              <div className="blogPostHeadMeta">
                <span className="blogPostHeadMeta_date">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                    <g>
                      <g>
                        <rect
                          className="cls-1"
                          width="48"
                          height="48"
                          fill="none"
                        />
                      </g>
                      <g className="blogPostHeadMeta_date-icon">
                        <path
                          className="cls-2"
                          d="M16,10H8a2,2,0,0,0-2,2V40a2,2,0,0,0,2,2H40a2,2,0,0,0,2-2V12a2,2,0,0,0-2-2H38"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="4px"
                        />
                        <line
                          className="cls-2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="4px"
                          x1="22"
                          y1="10"
                          x2="32"
                          y2="10"
                        />
                        <line
                          className="cls-2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="4px"
                          x1="16"
                          y1="6"
                          x2="16"
                          y2="14"
                        />
                        <line
                          className="cls-2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="4px"
                          x1="32"
                          y1="6"
                          x2="32"
                          y2="14"
                        />
                        <line
                          className="cls-2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="4px"
                          x1="6"
                          y1="21"
                          x2="34"
                          y2="21"
                        />
                      </g>
                    </g>
                  </svg>
                  <span className="blogPostHeadMeta_date-txt">
                    published on : {post.date}
                  </span>
                </span>
                {post.date < post.modified && (
                  <span className="blogPostHeadMeta_modified">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                      <g>
                        <g>
                          <rect
                            className="cls-1"
                            width="48"
                            height="48"
                            fill="none"
                          />
                        </g>
                        <g className="blogPostHeadMeta_modified-icon">
                          <polyline
                            className="cls-2"
                            points="17.18 15 8.18 15 8.18 6"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="4px"
                          />
                          <path
                            className="cls-2"
                            d="M10.58,12A18,18,0,1,1,6.23,26.88"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="4px"
                          />
                        </g>
                      </g>
                    </svg>
                    <span className="blogPostHeadMeta_modified-txt">
                      modified on : {post.modified}
                    </span>
                  </span>
                )}
                <AniLink
                  fade
                  duration={1}
                  to={`/categories/${post.categories[0].slug}`}
                  className="blogPostHeadMeta_cat"
                >
                  <span>{post.categories[0].name}</span>
                </AniLink>
              </div>
              <h1 className="blogPostHead_title">{post.title}</h1>
            </div>
            <div
              className="blogPostContent"
              dangerouslySetInnerHTML={{ __html: post.content }}
            />

            <div className="snsLinksWrap">
              <h3>Share if you like</h3>
              <div className="snsLinks">
                <FacebookShareButton url={`https://taroken.dev/${post.slug}`}>
                  <FacebookIcon size={42} round />
                </FacebookShareButton>
                <LineShareButton url={`https://taroken.dev/${post.slug}`}>
                  <LineIcon title={post.title} size={42} round />
                </LineShareButton>
                <TwitterShareButton
                  title={post.title}
                  via="kentaro_koga"
                  url={`https://taroken.dev/${post.slug}`}
                >
                  <TwitterIcon size={42} round />
                </TwitterShareButton>
              </div>
            </div>

            <Comment location={location} post={post} />

            <MiniBio />

            <Banner />

            {relatedPosts.length ? (
              <RelatedPosts posts={relatedPosts} />
            ) : (
              <p className="noRelatedPosts">
                あ、関連記事がなかけん最新記事ば読んでくれたらばり嬉しかぁ^ ^
              </p>
            )}

            <RecentPosts />
          </div>
        </div>
      </Layout>
    )
  }
}
export default BlogPostTemplate

export const query = graphql`
  query($id: Int!) {
    wordpressPost(wordpress_id: { eq: $id }) {
      title
      content
      excerpt
      slug
      date(formatString: "YYYY.MM.DD")
      modified(formatString: "YYYY.MM.DD")
      categories {
        name
        slug
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
