import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"

import "../scss/components/recentPosts.scss"

const RecentPosts = () => (
  <StaticQuery
    query={graphql`
      {
        allWordpressPost(limit: 9) {
          nodes {
            id
            title
            slug
            date(formatString: "YYYY.MM.DD")
            modified(formatString: "YYYY.MM.DD")
            categories {
              name
              slug
            }
            featured_media {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className="recentPosts">
        <h3 className="recentSectionTitle">
          <span className="recentSectionTitle_l">
            Recent<span> </span>Posts
          </span>
          <span className="recentSectionTitle_s">最新記事</span>
        </h3>

        <ul className="recentPostsList">
          {data.allWordpressPost.nodes.map(node => (
            <li key={node.id} className="recentPostsList_item">
              <AniLink
                fade
                duration={1}
                to={`/${node.slug}`}
                className="recentPostsList_img"
              >
                {node.featured_media && (
                  <Img
                    fluid={node.featured_media.localFile.childImageSharp.fluid}
                    alt={node.title}
                  />
                )}
              </AniLink>
              <div className="recentPostsInfo">
                <span className="recentPostsInfoMeta">
                  <span className="recentPostsInfoMeta_date">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                      <g>
                        <g>
                          <rect
                            className="cls-1"
                            width="48"
                            height="48"
                            fill="none"
                          />
                        </g>
                        <g className="recentPostsInfoMeta_date-icon">
                          <path
                            className="cls-2"
                            d="M16,10H8a2,2,0,0,0-2,2V40a2,2,0,0,0,2,2H40a2,2,0,0,0,2-2V12a2,2,0,0,0-2-2H38"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="4px"
                          />
                          <line
                            className="cls-2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="4px"
                            x1="22"
                            y1="10"
                            x2="32"
                            y2="10"
                          />
                          <line
                            className="cls-2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="4px"
                            x1="16"
                            y1="6"
                            x2="16"
                            y2="14"
                          />
                          <line
                            className="cls-2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="4px"
                            x1="32"
                            y1="6"
                            x2="32"
                            y2="14"
                          />
                          <line
                            className="cls-2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="4px"
                            x1="6"
                            y1="21"
                            x2="34"
                            y2="21"
                          />
                        </g>
                      </g>
                    </svg>
                    <span className="recentPostsInfoMeta_date-txt">
                      {node.date}
                    </span>
                  </span>
                  {node.date < node.modified && (
                    <span className="recentPostsInfoMeta_modified">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                      >
                        <g>
                          <g>
                            <rect
                              className="cls-1"
                              width="48"
                              height="48"
                              fill="none"
                            />
                          </g>
                          <g className="recentPostsInfoMeta_modified-icon">
                            <polyline
                              className="cls-2"
                              points="17.18 15 8.18 15 8.18 6"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="4px"
                            />
                            <path
                              className="cls-2"
                              d="M10.58,12A18,18,0,1,1,6.23,26.88"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="4px"
                            />
                          </g>
                        </g>
                      </svg>
                      <span className="recentPostsInfoMeta_modified-txt">
                        {node.modified}
                      </span>
                    </span>
                  )}
                  <AniLink
                    fade
                    duration={1}
                    to={`/categories/${node.categories[0].slug}`}
                    className="recentPostsInfoMeta_cat"
                  >
                    <span>{node.categories[0].name}</span>
                  </AniLink>
                </span>
                <AniLink fade duration={1} to={`/${node.slug}`}>
                  <h3
                    className="recentPostsInfo_title"
                    dangerouslySetInnerHTML={{ __html: node.title }}
                  />
                </AniLink>
              </div>
            </li>
          ))}
        </ul>
      </div>
    )}
  />
)

export default RecentPosts
