import React from "react"
import Disqus from "gatsby-plugin-disqus"

import "../scss/components/comment.scss"

const Comment = ({ location, post }) => {
  let disqusConfig = {
    url: `${location.href}`,
    identifier: post.slug,
    title: post.title,
  }
  return (
    <>
      <div className="commentArea">
        <h3 className="commentAreaTitle">
          <span className="commentAreaTitle_l">Comment</span>
          <span className="commentAreaTitle_s">なんでも気軽にどうぞ^ ^</span>
        </h3>
        <Disqus config={disqusConfig} />
      </div>
    </>
  )
}

export default Comment
