import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import TwitterIcon from "../images/tw_icon.svg"
import InstagramIcon from "../images/insta_icon.svg"
import FacebookIcon from "../images/fb_icon.svg"
import "../scss/components/miniBio.scss"

const MiniBio = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
            childImageSharp {
              fixed(width: 80, height: 80) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <div className="miniBio">
            <span className="miniBio_label">
              <span className="miniBio_label-txt">Author</span>
              <span className="miniBio_label-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="57.082" height="56.532" viewBox="0 0 57.082 56.532"><g transform="translate(369.848 -510.214) rotate(45)"><path d="M10.117,0A10.231,10.231,0,0,1,20.233,10.342v24.28L10.117,44.963,0,34.622V10.342A10.231,10.231,0,0,1,10.117,0Z" transform="translate(129.5 594.052)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/><path d="M21.649,37.24,11.5,47.842Z" transform="translate(127.892 580.188)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/><path d="M12.5,38.24l9.824,9.6Z" transform="translate(117.217 576.188)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/><path d="M21.649,37.24,11.5,47.842Z" transform="translate(127.892 570.188)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/><path d="M12.5,38.24l9.824,9.6Z" transform="translate(117.217 566.188)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/><path d="M-.014,0H.978V62.948l-1,2.72Z" transform="translate(138.75 594.759)" strokeWidth="2"/></g></svg>
              </span>
            </span>
            <div className="miniBioHead">
              <Image className="miniBioHead_img" fixed={data.avatar.childImageSharp.fixed} />
              <div className="miniBioHead_meta">
                <span className="miniBioHead_meta-job">Web Designer</span>
                <h4 className="miniBioHead_meta-name">Kentaro Koga</h4>
              </div>
            </div>

            <div className="miniBioInfo">
              <p>福岡を拠点のクリエイターチーム「<a href="https://fump.tech" target="_blank" rel="noopener noreferrer">Fump</a>」として活動をしています。</p>
              <p>写真や動画の撮影やモノ作りが好きで、趣味は旅・カメラ・テニス・筋トレ・サウナ・レザークラフトです。</p>
            </div>

            <div className="miniBioSns">
              <a href="https://twitter.com/kentaro_koga" target="_blank" rel="noopener noreferrer">
                <img src={TwitterIcon} alt="twitterアイコン"/>
              </a>
              <a href="https://www.instagram.com/taroken1210" target="_blank" rel="noopener noreferrer">
                <img src={InstagramIcon} alt="instagramアイコン"/>
              </a>
              <a href="https://www.facebook.com/kentaro.koga.7" target="_blank" rel="noopener noreferrer">
                <img src={FacebookIcon} alt="facebookアイコン"/>
              </a>
            </div>

          </div>
        )
      }}
    />
  )
}

export default MiniBio
